/*
 * ---------------------------------------------
 * Author: Chui Perez
 * Date: Sunday July 3rd 2022
 * Last Modified by: Chui Perez - <chui@healthnow.ph>
 * Last Modified time: July 22nd 2022, 1:29:19 am
 * ---------------------------------------------
 */
"use strict";

import $ from "jquery";
import _ from "lodash";

require("slick");
require("pagepiling");

const fullPageMain = () => {
  const $verticalSlider = $(".main-page");

  $verticalSlider.on('init', (function (e, slick) {
    $('.menu-item', $verticalSlider).on('click', (function (e) {
      e.preventDefault();

      const position = $(this).index();
      $verticalSlider.slick('slickGoTo', position + 1);
    }));
  }));

  $verticalSlider.slick({
    vertical: true,
    verticalSwiping: true,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    dots: true,
    swipeToSlide: true,
    speed: 600,
    draggable: false,
    responsive: [
      {
        breakpoint: 770,
        settings: "unslick"
      }
    ]
  });

  $verticalSlider.on('wheel', (function (e) {
    e.preventDefault();
    if (e.originalEvent.deltaY > 0) {
      $(this).slick('slickNext');
    } else {
      $(this).slick('slickPrev');
    }
  }));

  $verticalSlider.on('afterChange', (function (e, slick) {
    const position = slick.currentSlide;

    if ($(slick.$slider[0]).hasClass('slick-vertical')) {
      $('.menu-item', $verticalSlider).removeClass('active');
      $(`.menu-item:nth-child(${position})`, $verticalSlider).addClass('active');
    }
  }));
};

const slickMain = () => {
  const $horizontalSlider = $('.all-how-to');

  // $horizontalSlider.on('init', (function (e, slick) {
  //   $('.custom-dots-container').prepend(slick.$dots);
  // }));

  $horizontalSlider.on('afterChange', (function (e, slick) {
    const position = slick.currentSlide;

    if (!$(slick.$slider[0]).hasClass('slick-vertical')) {
      $('.custom-dots-container > .slick-dots > li').removeClass('slick-active');
      $(`.custom-dots-container > .slick-dots > li:nth-child(${position + 1})`).addClass('slick-active');
    }
  }));

  $horizontalSlider.slick({
    arrows: false,
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    draggable: false,
    autoplay: false,
    autoplaySpeed: 7000,
  });
}

const menuToggle = () => {
  $('.burger').on('click', (function (e) {
    e.preventDefault();
    e.stopPropagation();

    $(this).parent().next().toggleClass('active');
  }));

  $(window).on('click', (function (e) {
    const $mobileMenu = $('.menu-main-menu-container', '.mobile-menu');

    if($mobileMenu.hasClass('active')) $mobileMenu.removeClass('active');
  }));
}

const menuItemToggle = () => {
  $('.menu-item > a').on('click', (function (e) {
    e.stopPropagation();
    
    $('.menu-item').removeClass('active');
    $(this).parent().addClass('active');
  }))
}

(() => {
  // fullPageMain();
  slickMain();
  menuToggle();
  menuItemToggle();
})();
